<template>
  <div class="app-container">
    <baseFormGroup v-model="form" :option="formOption" :readonly="true" @reset-change="goBack">
      <template #columnAppend="{ form, prop, column }">
        <insertVariable v-if="column.insertVariable" class="ml10" :data="column.appendData"/>
        <span>
          <baseButton
            type="text"
            size="mini"
            class="ml10"
            @click="column.required = columnMap[column.id].isExcelRequired = !columnMap[column.id].isExcelRequired"
          >
            改为{{ columnMap[column.id].isExcelRequired ? '非' : '' }}必填
          </baseButton>
        </span>
      </template>
      <template #image>
        <imgUpload size="100px" disabled></imgUpload>
      </template>
      <template #menuForm>
        <baseButton type="primary" size="mini" @click="onsubmit">保存</baseButton>
      </template>
    </baseFormGroup>
  </div>
</template>

<script>
import baseFormGroup from '@/components/baseFormGroup'
import insertVariable from '@/views/productTemplate/addTemplate/module/insertVariable'
import templateApi from '@/api/productTemplate/index'
import imgUpload from '@/components/fileUpload/imgUpload'
import { FIELD_TYPE_DIC } from '@/utils/constant/fieldConst'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  components: { baseFormGroup, insertVariable, imgUpload },
  data() {
    return {
      form: {},
      data: [],
      fieldList: [],

      columnMap: {}
    }
  },
  computed: {
    formOption({ fieldGroup }) {
      return {
        emptyText: '关闭',
        menuBtn: true,
        submitBtn: false,
        labelWidth: 188,
        gutter: 20,
        group: fieldGroup
      }
    },
    fieldGroup({ data, fieldTypes }) {
      return data.map((group) => ({
        label: group.groupName,
        column: group.analysisResultVOS?.map((field) => {
          const column = {
            label: field.cnName || field.fieldName,
            prop: field.fieldName,
            value: field.customDefaultValue,
            id: field.id
          }

          const fieldType = fieldTypes[field.fieldType]
          switch (fieldType) {
            case 'image':
              column.formslotName = fieldType
              break
            case 'select':
              column.readonly = false // 显示下拉框
              column.type = 'select'
              column.dicData = field.validValues?.map(value => ({ value, disabled: true }))
              column.props = { label: 'value', value: 'value' }
              break
            default:
              column.type = fieldType
          }

          column.appendSlot = true
          if (!validatenull(field.customfieldVOS)) {
            column.insertVariable = true
            column.appendData = field.customfieldVOS
          }

          column.isExcelRequired = field.isExcelRequired
          column.required = !!field.isExcelRequired

          return column
        })
      }))
    },
    fieldTypes() {
      const tmpObj = {}
      FIELD_TYPE_DIC.forEach(item => {
        tmpObj[item.value] = item.localValue
      })
      return tmpObj
    }
  },
  watch: {
    fieldGroup: {
      handler(n) {
        const columnMap = {}
        for (const group of n) {
          for (const column of group.column) {
            columnMap[column.id] = { isExcelRequired: column.isExcelRequired, column }
          }
        }
        this.columnMap = columnMap
      },
      immediate: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    async onsubmit() {
      const { columnMap } = this
      const list = []
      for (const id in columnMap) {
        const { isExcelRequired, column } = columnMap[id]
        const tmpObj = { id, isExcelRequired: isExcelRequired ? 1 : 0 }
        if (tmpObj.isExcelRequired !== column.isExcelRequired) {
          list.push(tmpObj)
        }
      }

      let res = true
      if (list.length) {
        res = await awaitResolveLoading(
          templateApi.isRequiredUpdate({ list })
        )
      }
      if (res) {
        this.goBack()
      }
    },
    async init() {
      const res = await awaitResolveLoading(
        templateApi.analysisResult({ id: this.$route.query.id })
      )
      if (!res) return

      this.data = res.detail
    },

    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    max-width: 900px;
  }
  .el-form-item__label {
    white-space: normal;
    word-break: break-all;
  }
  .column-append {
    .el-form-item__content {
      display: flex;
    }
  }
}
</style>