<template>
  <div
    class="base-form-group"
    :class="{ 'is-loading': loadLoading }"
    v-loading="isLoading && loadLoading"
    element-loading-text="解析中"
  >
    <template v-if="!loadLoading">
      <baseForm1
        v-for="(group, index) in groupOption"
        :key="index"
        ref="form"
        class="form-group__item"
        :class="{ 'is-group': groupOption.length > 1 }"
        :option="group"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-for="name in groupSlots[index]" v-slot:[name]="scope">
          <slot :name="name" v-bind="scope"></slot>
        </template>
      </baseForm1>
    </template>
  </div>
</template>

<script>
import propsOriginMixin from '@/mixins/parentProps/propsOriginMixin'
import { findIndex, flatten, map, difference } from 'lodash'
import { scrollView } from '@/utils/dom'

export default {
  mixins: [propsOriginMixin],
  props: {
    option: {
      type: Object,
      default: () => ({})
    },
    loading: Boolean,
    isLoading: {
      type: Boolean,
      default: true
    },
    offset: Number
  },
  data() {
    return {
      loadLoading: true
    }
  },
  computed: {
    groupOption({ option }) {
      return !option.group
        ? [option]
        : option.group
            .filter((group) => !group.hide)
            .map((group, index, arr) => {
              const tmpObj = {
                ...option,
                ...group,
                group: undefined
              }
              tmpObj.menuBtn = index === arr.length - 1 ? tmpObj.menuBtn : false
              return tmpObj
            })
    },
    groups({ groupOption }) {
      return flatten(
        groupOption.map((item) => {
          return item.group ? item.group : [item]
        })
      )
    },
    groupSlotNames({ groupOption }) {
      return groupOption.map((group) => {
        const slots = []
        const column = [...(group.column || []), ...flatten(map(group.group, 'column'))]
        column?.forEach((ele) => {
          if (ele.formslot !== false) {
            slots.push(ele.slotName || ele.prop)
          }
        })
        return slots
      })
    },
    groupSlots({ groupSlotNames, $scopedSlots }) {
      // 每个group的插槽
      const slots = groupSlotNames.map((slotNames) => slotNames.filter((slotName) => $scopedSlots[slotName]))
      // $scopedSlots分配给每个group之后剩余插槽（每个group都有的插槽）
      const restSlots = difference(Object.keys($scopedSlots), ...slots)
      return slots.map((slotNames) => slotNames.concat(restSlots))
    }
  },
  watch: {
    loading: {
      handler(loading) {
        this.loadLoading = loading
        this.$emit('loading', loading)
      },
      immediate: true
    },
    option: {
      handler() {
        this.$off('loading', this.closeLoading)
        this.loadLoading = true
        setTimeout(() => {
          this.$nextTick(function () {
            setTimeout(() => {
              if (this.loading) {
                // 正在计算option（请求接口数据生成动态option等）
                this.$on('loading', this.closeLoading)
              } else {
                this.loadLoading = false
              }
            })
          })
        })
      },
      immediate: true
    },
    loadLoading: {
      handler(loadLoading) {
        this.$emit('update:loadLoading', loadLoading)
      },
      immediate: true
    }
  },
  methods: {
    async validate() {
      for (const form of this.$refs.form) {
        try {
          await form.validate()
        } catch (e) {
          this.scrollIntoView(form.$el.getElementsByClassName('el-form-item is-error')[0])
          throw e
        }
      }
      return true
    },

    closeLoading() {
      this.loadLoading = false
      this.$off('loading', this.closeLoading)
    },

    scrollIntoViewByProp(prop) {
      return this.scrollIntoView(this.getFormInstance(prop))
    },
    scrollIntoView(node) {
      return scrollView({ target: node?.$el || node, offset: this.offset })
    },
    getFormInstance(prop) {
      const { form } = this.$refs
      if (!form) return
      return form[findIndex(this.groups, { prop })] || form[prop] || form[0]
    },
    clearValidate(prop) {
      setTimeout(() => {
        this.$refs.form?.map((form) => form.clearValidate(prop))
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-loading {
  height: 250px;
}
</style>
